/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable */
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import creditCardType from 'credit-card-type';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import InputAdornment from '@material-ui/core/InputAdornment';
import { ReactInputMask } from 'react-input-mask';

import { api, cepApi, predictaPaymentApi } from './utils/api';

import Input from './Layout/Input';
import SelectInput from './Layout/Select';

import {
  BirthDateMask,
  CardNumberMask,
  DocumentCPFMask,
  ExpirationDateMask,
  PhoneNumberMask,
  ZipCodeMask
} from './masks';

import {
  formSchema,
  formSchemaFullDiscount,
  formSchemaOne,
  formSchemaOneFullDiscount
} from './schemas';

import getValidationErrors from './utils/getValidationErrors';
import paymentIcons from './utils/icons-payment';
import isCardNumberValid from './utils/validateCreditCard';

import { useInstallments } from '../../../hooks/installments';
import { useAuth } from './hooks/auth';
import { useLoading } from './hooks/loading';
import { useWarning } from './hooks/warning';

import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { useAuth as useAuthPredicta } from '../../../contexts/Auth/context';
import { useVoucher } from '../../../contexts/Voucher/context';
import { ScheduleRootState } from '../../../store/redux';
import AppCookies from '../../../utils/cookies';
import { Cpf, DateM, Phone } from '../../../utils/masks';
import { Button, Container, ContainerCardFields } from './styles';

interface IAddress {
  zipCode: string;
  street: string;
  district: string;
  number: string;
  complement?: string;
  city: string;
  state: string;
}

interface IDeliveryAddress {
  zipCode?: string;
  street?: string;
  district?: string;
  number?: string;
  complement?: string;
  city?: string;
  state?: string;
}

interface ICreditCard {
  cardNumber: string;
  expirationDate: string;
  securityCode: string;
}

interface IFormInput {
  installments: string;
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: string;
  phoneNumber: string;
  nationality: string;
  document: string;
  rg: string;
  email: string;
  address: IAddress;
  creditCard: ICreditCard;
  documentB: string;
  firstNameB: string;
  lastNameB: string;
  genderB: string;
  birthDateB: string;
  phoneNumberB: string;
  nationalityB: string;
  rgB: string;
  emailB: string;
  addressBeneficiary?: IDeliveryAddress;
}

interface IBeneficiaryData {
  documentOwner: string;
  paymentId: string;
  planId: number | string;
  document: string;
  name: string;
  lastName: string;
  birthDate: string;
  sex: string;
  phone: string;
  nationality: string;
  documentType: string;
  email: string;
  zipCode: string | null | undefined;
  address: string | null | undefined;
  state: string | null | undefined;
  city: string | null | undefined;
  addressNumber: string | null | undefined;
  complement: string | null | undefined;
}

interface IPayment {
  nit: string;
  storeToken: string;
  merchantId: string;
  paymentId: string;
}

interface IEsitefResponse {
  code: any;
  message: any;
  payment: {
    authorizer_code: any;
    authorizer_message: any;
    status: any;
    nit: any;
    order_id: any;
    customer_receipt: any;
    authorizer_id: any;
  };
}

type IEsitefCreditCard = Omit<ICreditCard, 'expirationDate'> & {
  expirationMonth: string;
  expirationYear: string;
};

interface ITouched {
  [key: string]: boolean;
}

interface IErrors {
  [key: string]: string;
}

type props = {
  product: number | string;
  service_id: number | string;
  numberProductPlan: number;
  propsInputMask?: ReactInputMask;
};

export interface BuyerDto {
  document: string;
  name: string;
  email: string;
  gender: string;
  nationality: string;
  birthday: string;
  ddi: string;
  phone: string;
}

export interface BeneficiaryDto {
  name: string;
  birthday: string;
  gender: string;
  nationality: string;
  document: string;
  email: string;
  ddi: string;
  phone: string;
  isOwner: boolean;
}

export interface AddressDto {
  street?: string;
  number?: string;
  state?: string;
  district?: string;
  cep?: string;
  city?: string;
  complement?: string;
}

export interface CardDto {
  number: string;
  securityCode: string;
  expiration: string;
  flag: string;
}

export type ICreatePaymentOrder = {
  buyer: BuyerDto;
  beneficiaries: BeneficiaryDto[];
  productId: string;
  billingAddress: AddressDto;
  deliveryAddress?: AddressDto;
  voucher?: string;
  card: CardDto;
  installments: number;
  installmentType: string;
  totalDependents: number;
};

const PAYMENT_API_KEY = process.env.REACT_APP_PAYMENT_API_KEY;

const PaymentForm = (props: props) => {
  const fullDiscountVoucher = useSelector<ScheduleRootState, boolean>(
    (scheduleReducer) => scheduleReducer.fullDiscountVoucher
  );
  const formRef = useRef<FormHandles>(null);
  const { voucher } = useVoucher();

  const history = useHistory();

  const { access_token, source_platform, token, patient_token } = useAuth();
  const { user, setSuccessPaymentToken } = useAuthPredicta();
  const { product: product_id, service_id } = props;
  const { isLoading, startLoading, endLoading } = useLoading();
  const { showWarning } = useWarning();
  const { installments } = useInstallments();

  const [cardType, setCardType] = useState('');
  const [cardMask, setCardMask] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [creditCard, setCreditCard] = useState<IEsitefCreditCard>(
    {} as IEsitefCreditCard
  );
  const [chosedNationality, setChosedNationality] = useState('');
  const [installmentsList, setInstallmentsList] = useState<string[]>([]);

  const [touched, setTouched] = useState<ITouched>({} as ITouched);
  const [shrinked, setShrinked] = useState<ITouched>({} as ITouched);
  const [shrinkedB, setShrinkedB] = useState<ITouched>({} as ITouched);
  const [shrinkedC, setShrinkedC] = useState<ITouched>({} as ITouched);
  const [inputErrors, setInputErrors] = useState<IErrors>({} as IErrors);
  const [copy, setCopy] = useState(false);
  const [documentB, setDocumentB] = useState('');
  const [birthDateB, setBirthDateB] = useState('');
  const [phoneNumberB, setPhoneNumberB] = useState('');
  const [genderB, setGenderB] = useState('');
  const [nationalityB, setNationalityB] = useState('');

  const [submissionAttempts, setSubmissionAttempts] = useState(1);

  const cleanValues = useMemo(() => /[^0-9]+/g, []);
  const cleanMaskRG = useMemo(() => /-|\./g, []);

  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  useEffect(() => {
    const installmentsValues = [];

    if (installments.hasInstallments) {
      for (let i = 1; i <= installments.installmentsMax; i += 1) {
        if (i <= installments.installmentsNotFees) {
          const installmentWithoutFees = Number(installments.price / i).toFixed(
            2
          );

          const amountWithoutFees = Number(installments.price).toFixed(2);

          installmentsValues.push(
            `${i} x R$${installmentWithoutFees} = R$${amountWithoutFees}`
          );
        } else {
          const installmentWithFees = Number(
            (installments.price * installments.installmentsPercentFees) / i
          ).toFixed(2);

          const amountWithFees = Number(
            installments.installmentsPercentFees * installments.price
          ).toFixed(2);

          installmentsValues.push(
            `${i} x R$${installmentWithFees} = R$${amountWithFees} (com juros)`
          );
        }
      }
    }

    setInstallmentsList(installmentsValues);
  }, [installments]);

  function handleCopy(e: React.ChangeEvent<HTMLInputElement>): void {
    const current = formRef.current?.getData();
    formRef.current?.setData({
      firstNameB: current?.firstName,
      lastNameB: current?.lastName,
      birthDateB: current?.birthDate,
      genderB: current?.gender,
      phoneNumberB: current?.phoneNumber,
      nationalityB: current?.nationality,
      emailB: current?.email,
      documentB: current?.document,
      rgB: current?.rg
    });

    if (current?.document) setDocumentB(Cpf(current?.document));
    if (current?.phoneNumber) setPhoneNumberB(Phone(current?.phoneNumber));
    if (current?.birthDate) setBirthDateB(DateM(current?.birthDate));
    if (current?.gender) setGenderB(current?.gender);
    if (current?.nationality) setNationalityB(current?.nationality);

    setShrinkedC({
      firstNameB: !!current?.firstName,
      lastNameB: !!current?.lastName,
      birthDateB: !!current?.birthDate,
      genderB: !!current?.gender,
      phoneNumberB: !!current?.phoneNumber,
      nationalityB: !!current?.nationality,
      emailB: !!current?.email,
      documentB: !!current?.document,
      rgB: !!current?.rg
    });
    setCopy(true);
  }

  function handleSwitchPlan(idPlan: string | number): number {
    if (idPlan === process.env.REACT_APP_PREDICTA_PRODUCT_ONE_ID) {
      return 1;
    }

    return 2;
  }

  function formatPhone(phoneNumber: string) {
    const cleanNumber = phoneNumber.replace(/\D/g, '');

    const formatedNumber = cleanNumber.replace(
      /^(\d{2})(\d{5})(\d{4})$/,
      '($1) $2-$3'
    );

    return formatedNumber;
  }

  const handleSubmit = useCallback(
    async (data: IFormInput) => {
      setSubmissionAttempts((prev) => prev + 1);

      const deliveryAddress: AddressDto = {
        cep: data.addressBeneficiary?.zipCode,
        street: data.addressBeneficiary?.street,
        district: data.addressBeneficiary?.district,
        city: data.addressBeneficiary?.city,
        number: data.addressBeneficiary?.number,
        state: data.addressBeneficiary?.state,
        complement: data.addressBeneficiary?.complement
          ? data.addressBeneficiary?.complement
          : ''
      };

      try {

        const access_token = `Bearer ${AppCookies.get('api_token')}`;        
        //subistituir pelo token

        formRef.current?.setErrors({});
        setInputErrors({});

        if (data.nationality === 'brasileiro') {
          data.rg = data.rg.replace(cleanMaskRG, '');
          data.rgB = data.rgB.replace(cleanMaskRG, '');
        }

        if (product_id === process.env.REACT_APP_PREDICTA_PRODUCT_ONE_ID) {
          await formSchemaOne.validate(data, { abortEarly: false });
        } else if (
          product_id === process.env.REACT_APP_PREDICTA_PRODUCT_INFINITY_ID
        ) {
          await formSchema.validate(data, { abortEarly: false });
        }

        let document = '';

        if (data.nationality === 'brasileiro') {
          document = data.document.replace(cleanValues, '');
        } else if (data.nationality === 'estrangeiro') {
          document = data.document.replace(/[^0-9a-z]+/gi, '');
        }

        const zipCode = data.address.zipCode.replace(cleanValues, '');

        const beneficiaries: BeneficiaryDto[] = [];

        beneficiaries.push({
          name: `${data.firstNameB} ${data.lastNameB}`,
          birthday: data.birthDateB.split('/').reverse().join('-'),
          gender: data.genderB === 'masculino' ? 'M' : 'F',
          nationality:
            data.nationalityB === 'brasileiro' ? 'BRASILEIRO' : 'ESTRANGEIRO',
          document: data.documentB,
          email: data.emailB,
          ddi: '55',
          phone: formatPhone(data.phoneNumberB.replace(cleanValues, '')),
          isOwner: true
        });

        const installmentsNumber = installmentsList.findIndex(
          (installment) => installment.toLowerCase() === data.installments
        );

        const paymentOrder: ICreatePaymentOrder = {
          buyer: {
            document: data.document,
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
            gender: data.gender === 'masculino' ? 'M' : 'F',
            nationality: data.nationality.toUpperCase(),
            birthday: data.birthDate.split('/').reverse().join('-'),
            ddi: '55',
            phone: formatPhone(data.phoneNumber.replace(cleanValues, ''))
          },
          beneficiaries,
          productId: product_id.toString(),
          billingAddress: {
            street: data.address.street,
            number: data.address.number,
            district: data.address.district,
            state: data.address.state,
            city: data.address.city,
            cep: zipCode,
            complement: data.address.complement ?? ''
          },
          deliveryAddress: data.addressBeneficiary
            ? deliveryAddress
            : undefined,
          voucher: voucher ? voucher : undefined,
          card: {
            number: data.creditCard.cardNumber.replace(/ /g, ''),
            securityCode: data.creditCard.securityCode,
            expiration: data.creditCard.expirationDate.replace(/\//, ''),
            flag: cardType
          },
          installments: installmentsNumber + 1,
          installmentType: 'LOJA_SEM_JUROS',
          totalDependents: 1
        };

        if (!paymentOrder.productId) {
          showWarning({
            title: 'O Produto não foi escolhido!',
            description:
              'Por favor, escolha um produto para que o pagamento seja efetuado.',
            signal: 'warning'
          });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          startLoading();

          try {
            const response = await api.post(
              '/v2.0/process-payment',
              paymentOrder,
              {
                headers: {
                  'x-api-key': PAYMENT_API_KEY,
                  'x-access-token': `Bearer ${access_token}`,
                  'x-access-patient-token': `Bearer ${AppCookies.get(
                    'api_patientToken'
                  )}`
                }
              }
            );

            const beneficiaryData: IBeneficiaryData = {
              documentOwner:
                data.nationalityB === 'brasileiro'
                  ? response.data.buyer.document.replace(cleanValues, '')
                  : response.data.buyer.document,
              paymentId: response.data.id,
              planId: handleSwitchPlan(response.data.product.id),
              document:
                data.nationalityB === 'brasileiro'
                  ? data.documentB.replace(cleanValues, '')
                  : data.documentB,
              name: data.firstNameB,
              lastName: data.lastNameB,
              birthDate: data.birthDateB.split('/').reverse().join('-'),
              sex: data.genderB.charAt(0).toUpperCase(),
              phone: formatPhone(data.phoneNumberB.replace(cleanValues, '')),
              nationality: data.nationalityB,
              documentType:
                data.nationalityB === 'brasileiro' ? 'cpf' : 'passaporte',
              email: data.emailB,
              zipCode: data.addressBeneficiary
                ? data?.addressBeneficiary?.zipCode
                : null,
              address: data.addressBeneficiary
                ? data?.addressBeneficiary?.street
                : null,
              state: data.addressBeneficiary
                ? data?.addressBeneficiary?.state
                : null,
              city: data.addressBeneficiary
                ? data.addressBeneficiary?.city
                : null,
              addressNumber: data.addressBeneficiary
                ? data.addressBeneficiary?.number
                : null,
              complement: data.addressBeneficiary
                ? data.addressBeneficiary?.complement
                : null
            };

            await predictaPaymentApi.post('/payment/insert', beneficiaryData, {
              headers: {
                'x-access-token': `Bearer ${access_token}`,
                'x-access-patient-token': `Bearer ${AppCookies.get(
                  'api_patientToken'
                )}`
              }
            });

            const tagManagerArgsSuccess = {
              gtmId: 'GTM-TDPD3K8',
              dataLayer: {
                event: 'ecommerce',
                transactionId: Math.floor(Math.random() * 99999),
                transactionAffiliation: 'Predicta',
                transactionTotal: 1,
                transactionTax: 0,
                transactionShipping: 0,
                transactionProducts: [
                  {
                    sku: product_id === 1 ? '1' : '2',
                    name:
                      product_id === 1 ? 'Predicta One' : 'Predicta Infinity',
                    category: 'Saude',
                    price: product_id === 1 ? '2700.00' : '16000.00',
                    quantity: '1'
                  }
                ]
              }
            };

            TagManager.initialize(tagManagerArgsSuccess);
            setSuccessPaymentToken(response.data.pin);

            endLoading();

            history.push({
              pathname: '/success',
              state: product_id
            });
          } catch (err: any) {
            showWarning({
              signal: 'error',
              title: 'Falha ao realizar Pagamento',
              description: err.response.data.error
            });
            endLoading();
          }
        }
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          setInputErrors(errors);

          const tagManagerArgsError = {
            gtmId: 'GTM-TDPD3K8',
            dataLayer: {
              event: 'Einstein-Predicta',
              categoria: 'Contratar-Agora',
              acao: 'step-4',
              rotulo: 'erro-no-pagamento'
            }
          };

          TagManager.initialize(tagManagerArgsError);
        } else {
          const tagManagerArgsError = {
            gtmId: 'GTM-TDPD3K8',
            dataLayer: {
              event: 'Einstein-Predicta',
              categoria: 'Contratar-Agora',
              acao: 'step-4',
              rotulo: 'erro-no-pagamento'
            }
          };

          TagManager.initialize(tagManagerArgsError);

          showWarning({ code: '3959', signal: 'error' });
          if (submissionAttempts === 6) {
            history.push('/attempts-exceeded');
          }
        }

        const tagManagerArgs = {
          gtmId: 'GTM-TDPD3K8',
          dataLayer: {
            event: 'Einstein-Predicta',
            categoria: 'Contratar-Agora',
            acao: 'step-4',
            rotulo: 'Confirmar-compra'
          }
        };

        TagManager.initialize(tagManagerArgs);

        endLoading();
      }
    },
    [
      cardType,
      cleanValues,
      access_token,
      product_id,
      service_id,
      endLoading,
      startLoading,
      chosedNationality,
      showWarning,
      submissionAttempts,
      history,
      source_platform,
      token,
      installmentsList,
      voucher,
      user
    ]
  );

  const handleSubmitFullDiscount = useCallback(
    async (data: IFormInput) => {
      setSubmissionAttempts((prev) => prev + 1);

      const deliveryAddress: AddressDto = {
        cep: data.addressBeneficiary?.zipCode,
        street: data.addressBeneficiary?.street,
        district: data.addressBeneficiary?.district,
        city: data.addressBeneficiary?.city,
        number: data.addressBeneficiary?.number,
        state: data.addressBeneficiary?.state,
        complement: data.addressBeneficiary?.complement
          ? data.addressBeneficiary?.complement
          : ''
      };

      try {
        const access_token = localStorage.getItem('api_token');
        //subistituir pelo token

        formRef.current?.setErrors({});
        setInputErrors({});

        if (data.nationality === 'brasileiro') {
          data.rg = data.rg.replace(cleanMaskRG, '');
          data.rgB = data.rgB.replace(cleanMaskRG, '');
        }

        if (product_id === process.env.REACT_APP_PREDICTA_PRODUCT_ONE_ID) {
          await formSchemaOneFullDiscount.validate(data, { abortEarly: false });
        } else if (
          product_id === process.env.REACT_APP_PREDICTA_PRODUCT_INFINITY_ID
        ) {
          await formSchemaFullDiscount.validate(data, { abortEarly: false });
        }
        const zipCode = data.address.zipCode.replace(cleanValues, '');

        const beneficiaries: BeneficiaryDto[] = [];

        beneficiaries.push({
          name: `${data.firstNameB} ${data.lastNameB}`,
          birthday: data.birthDateB.split('/').reverse().join('-'),
          gender: data.genderB === 'masculino' ? 'M' : 'F',
          nationality:
            data.nationalityB === 'brasileiro' ? 'BRASILEIRO' : 'ESTRANGEIRO',
          document: data.documentB,
          email: data.emailB,
          ddi: '55',
          phone: formatPhone(data.phoneNumberB.replace(cleanValues, '')),
          isOwner: true
        });

        const paymentOrder = {
          buyer: {
            name: `${data.firstNameB} ${data.lastNameB}`,
            birthday: data.birthDateB.split('/').reverse().join('-'),
            gender: data.genderB === 'masculino' ? 'M' : 'F',
            nationality:
              data.nationalityB === 'brasileiro' ? 'BRASILEIRO' : 'ESTRANGEIRO',
            document: data.documentB,
            email: data.emailB,
            ddi: '55',
            phone: data.phoneNumberB.replace(cleanValues, '')
          },
          beneficiaries,
          productId: product_id.toString(),
          productVoucher: '',
          billingAddress: {
            street: data.address.street,
            number: data.address.number,
            district: data.address.district,
            state: data.address.state,
            city: data.address.city,
            cep: zipCode,
            complement: data.address.complement ?? ''
          },
          deliveryAddress: data.addressBeneficiary
            ? deliveryAddress
            : undefined,
          voucher: voucher ? voucher : undefined,
          installmentType: 'LOJA_SEM_JUROS',
          totalDependents: 1
        };

        if (!paymentOrder.productId) {
          showWarning({
            title: 'O Produto não foi escolhido!',
            description:
              'Por favor, escolha um produto para que o pagamento seja efetuado.',
            signal: 'warning'
          });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (!voucher) {
          showWarning({
            title: 'Voucher não selecionado',
            description: 'Por favor, preencha o voucher corretamente.',
            signal: 'warning'
          });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          startLoading();

          try {
            const response = await api.post(
              '/v2.0/process-elegibility',
              paymentOrder,
              {
                headers: {
                  'x-api-key': PAYMENT_API_KEY,
                  'x-access-token': `Bearer ${access_token}`,
                  'x-access-patient-token': `Bearer ${String(
                    localStorage.getItem('api_patientToken')
                  )}`
                }
              }
            );

            // const beneficiaryData = {
            //   paymentId: response.data.data.paymentId,
            //   planId: handleSwitchPlan(paymentOrder.productId),
            //   document:
            //     data.nationalityB === 'brasileiro'
            //       ? data.documentB.replace(cleanValues, '')
            //       : data.documentB,
            //   documentOwner:
            //     data.nationalityB === 'brasileiro'
            //       ? data.documentB.replace(cleanValues, '')
            //       : data.documentB,
            //   name: data.firstNameB,
            //   lastName: data.lastNameB,
            //   birthDate: data.birthDateB.split('/').reverse().join('-'),
            //   sex: data.genderB.charAt(0).toUpperCase(),
            //   phone: data.phoneNumberB.replace(cleanValues, ''),
            //   nationality: data.nationalityB,
            //   documentType:
            //     data.nationalityB === 'brasileiro' ? 'cpf' : 'passaporte',
            //   email: data.emailB,
            //   zipCode: data.addressBeneficiary
            //     ? data?.addressBeneficiary?.zipCode
            //     : null,
            //   address: data.addressBeneficiary
            //     ? data?.addressBeneficiary?.street
            //     : null,
            //   state: data.addressBeneficiary
            //     ? data?.addressBeneficiary?.state
            //     : null,
            //   city: data.addressBeneficiary
            //     ? data.addressBeneficiary?.city
            //     : null,
            //   addressNumber: data.addressBeneficiary
            //     ? data.addressBeneficiary?.number
            //     : null,
            //   complement: data.addressBeneficiary
            //     ? data.addressBeneficiary?.complement
            //     : null
            // };

            // await predictaPaymentApi.post('/payment/insert', beneficiaryData, {
            //   headers: {
            //     'x-access-token': `Bearer ${access_token}`,
            //     'x-access-patient-token': patient_token,
            //   }
            // });

            const tagManagerArgsSuccess = {
              gtmId: 'GTM-TDPD3K8',
              dataLayer: {
                event: 'ecommerce',
                transactionId: Math.floor(Math.random() * 99999),
                transactionAffiliation: 'Predicta',
                transactionTotal: 1,
                transactionTax: 0,
                transactionShipping: 0,
                transactionProducts: [
                  {
                    sku: product_id === 1 ? '1' : '2',
                    name:
                      product_id === 1 ? 'Predicta One' : 'Predicta Infinity',
                    category: 'Saude',
                    price: product_id === 1 ? '2700.00' : '16000.00',
                    quantity: '1'
                  }
                ]
              }
            };

            TagManager.initialize(tagManagerArgsSuccess);
            setSuccessPaymentToken(response.data.pin);

            endLoading();

            history.push({
              pathname: '/success',
              state: product_id
            });
          } catch (err: any) {
            showWarning({
              signal: 'error',
              title: 'Falha ao realizar Pagamento',
              description: err.response.data.error
            });
            endLoading();
          }
        }
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          setInputErrors(errors);

          const tagManagerArgsError = {
            gtmId: 'GTM-TDPD3K8',
            dataLayer: {
              event: 'Einstein-Predicta',
              categoria: 'Contratar-Agora',
              acao: 'step-4',
              rotulo: 'erro-no-pagamento'
            }
          };

          TagManager.initialize(tagManagerArgsError);
        } else {
          const tagManagerArgsError = {
            gtmId: 'GTM-TDPD3K8',
            dataLayer: {
              event: 'Einstein-Predicta',
              categoria: 'Contratar-Agora',
              acao: 'step-4',
              rotulo: 'erro-no-pagamento'
            }
          };

          TagManager.initialize(tagManagerArgsError);

          showWarning({ code: '3959', signal: 'error' });
          if (submissionAttempts === 6) {
            history.push('/attempts-exceeded');
          }
        }

        const tagManagerArgs = {
          gtmId: 'GTM-TDPD3K8',
          dataLayer: {
            event: 'Einstein-Predicta',
            categoria: 'Contratar-Agora',
            acao: 'step-4',
            rotulo: 'Confirmar-compra'
          }
        };

        TagManager.initialize(tagManagerArgs);

        endLoading();
      }
    },
    [
      cardType,
      cleanValues,
      access_token,
      product_id,
      service_id,
      endLoading,
      startLoading,
      chosedNationality,
      showWarning,
      submissionAttempts,
      history,
      source_platform,
      token,
      installmentsList,
      voucher,
      user
    ]
  );

  const handleCreditCard = useCallback(
    (event: ChangeEvent<{ value: string }>, name: string) => {
      const { value } = event.target;
      const cleanNumber = value.replace(cleanValues, '');

      if (name === 'expirationDate') {
        const [expirationMonth, expirationYear] = value.split('/');

        setCreditCard((prev) => ({ ...prev, expirationMonth }));
        setCreditCard((prev) => ({ ...prev, expirationYear }));
      }

      if (name === 'securityCode') {
        setCreditCard((prev) => ({ ...prev, securityCode: value }));
      }

      if (name === 'cardNumber') {
        if (isCardNumberValid(Number(cleanNumber))) {
          const cardBrand = creditCardType(cleanNumber);
          setCreditCard((prev) => ({ ...prev, cardNumber: cleanNumber }));
          if (cardBrand.length !== 0) {
            if (cardBrand[0].type === 'visa') {
              setCardType('VISA');
              setCardMask('9999 9999 9999 9999');
            } else if (cardBrand[0].type === 'mastercard') {
              setCardType('MASTER');
              setCardMask('9999 9999 9999 9999');
            } else if (cardBrand[0].type === 'elo') {
              setCardType('ELO');
              setCardMask('9999 9999 9999 9999');
            } else if (cardBrand[0].type === 'american-express') {
              setCardType('AMERICAN_EXPRESS');
              setCardMask('9999 999999 99999');
            } else if (cardBrand[0].type === 'diners-club') {
              setCardType('DINERS');
              setCardMask('9999 999999 9999');
            } else {
              showWarning({
                title: 'Bandeira não aceita',
                description:
                  'Por favor, utilize um cartão de crédito MASTERCARD, VISA, ELO, AMEX ou DINERS.',
                signal: 'warning'
              });
            }
          }

          if (!cleanNumber) {
            setCardType('');
          }
        }
      }
    },
    [cleanValues]
  );

  const handleCEP = useCallback(
    async (event: ChangeEvent<{ value: unknown }>, type: string) => {
      const value = event.target.value as string;
      const cep = value.replace(cleanValues, '');
      const isOwner = type === 'owner';

      if (cep.length === 8) {
        try {
          const response = await cepApi.get(`/${cep}/json/`);

          const { localidade, logradouro, uf, erro, bairro } = response.data;

          if (!erro) {
            isOwner
              ? formRef.current?.setData({
                  address: {
                    zipCode: cep,
                    street: logradouro,
                    state: uf,
                    district: bairro,
                    city: localidade
                  }
                })
              : formRef.current?.setData({
                  addressBeneficiary: {
                    zipCode: cep,
                    street: logradouro,
                    district: bairro,
                    state: uf,
                    city: localidade
                  }
                });

            isOwner
              ? setShrinked({
                  ...shrinked,
                  street: true,
                  state: true,
                  district: true,
                  city: true
                })
              : setShrinkedB({
                  ...shrinkedB,
                  street: true,
                  district: true,
                  state: true,
                  city: true
                });
          } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });

            showWarning({
              code: '3569',
              title: 'Não foi possível encontrar seu CEP!',
              description: `Por favor, verifique se seu CEP está correto e digite
              novamente`,
              signal: 'warning'
            });
          }
        } catch {
          window.scrollTo({ top: 0, behavior: 'smooth' });

          showWarning({
            code: '3663',
            title: 'Houve um problema para encontrar seu CEP!',
            description: `Por favor, verifique se seu CEP está correto e digite
            novamente`,
            signal: 'warning'
          });
        }
      }
    },
    []
  );

  const handleNationalityDocument = useCallback(
    async (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setChosedNationality(value);
    },
    []
  );

  const handleNationalityDocumentB = useCallback(
    async (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setNationalityB(value);
      setChosedNationality(value);
    },
    []
  );

  const handleGenderB = useCallback(
    async (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setGenderB(value);
    },
    []
  );

  return (
    <Container fullDiscountVoucher={fullDiscountVoucher}>
      <Form
        ref={formRef}
        onSubmit={fullDiscountVoucher ? handleSubmitFullDiscount : handleSubmit}
      >
        <ContainerCardFields fullDiscountVoucher={fullDiscountVoucher}>
          <fieldset className="fieldset-container">
            <Scope path="creditCard">
              <Input
                label="Número do cartão *"
                name="cardNumber"
                inputProps={{
                  mask: cardMask
                }}
                InputProps={{
                  inputComponent: CardNumberMask as any,
                  endAdornment: paymentIcons.map((icon) => {
                    if (cardType === icon.label) {
                      return (
                        <InputAdornment key={icon.id} position="end">
                          <img
                            style={{
                              marginBottom: `${
                                inputErrors['creditCard.cardNumber'] ? 0 : '5px'
                              }`
                            }}
                            src={icon.icon}
                            width={45}
                            alt={icon.label}
                          />
                        </InputAdornment>
                      );
                    }
                    return null;
                  })
                }}
                onChange={(event: any) => handleCreditCard(event, 'cardNumber')}
                variant="outlined"
              />
              <div className="card-grid-container">
                <Input
                  label="Data de expiração *"
                  name="expirationDate"
                  InputProps={{
                    inputComponent: ExpirationDateMask as any
                  }}
                  onChange={(event: any) =>
                    handleCreditCard(event, 'expirationDate')
                  }
                  variant="outlined"
                />
                <Input
                  label="Código de segurança *"
                  name="securityCode"
                  onChange={(event: any) =>
                    handleCreditCard(event, 'securityCode')
                  }
                  variant="outlined"
                />
              </div>
            </Scope>
          </fieldset>
          <fieldset className="fieldset-container">
            {installments.hasInstallments && (
              <>
                <h1>Parcelamento</h1>
                <SelectInput
                  label="Parcelas *"
                  name="installments"
                  valueList={installmentsList}
                />
              </>
            )}
          </fieldset>
          <fieldset className="fieldset-container">
            <h1>Dados do titular do cartão</h1>
            <div className="grid-container">
              <Input label="Nome *" name="firstName" variant="outlined" />
              <Input label="Sobrenome *" name="lastName" variant="outlined" />
              <Input
                label="Data de nascimento *"
                name="birthDate"
                InputProps={{
                  inputComponent: BirthDateMask as any
                }}
                variant="outlined"
              />
              <SelectInput
                label="Sexo"
                name="gender"
                valueList={['Masculino', 'Feminino']}
              />
              <Input
                label="DDD + Telefone *"
                name="phoneNumber"
                InputProps={{
                  inputComponent: PhoneNumberMask as any
                }}
                variant="outlined"
              />
              <SelectInput
                label="Nacionalidade"
                name="nationality"
                valueList={['Brasileiro', 'Estrangeiro']}
                onChange={handleNationalityDocument}
              />
              {chosedNationality === 'brasileiro' && (
                <>
                  <Input
                    label="CPF *"
                    name="document"
                    InputProps={{
                      inputComponent: DocumentCPFMask as any
                    }}
                    variant="outlined"
                  />
                  <Input label="RG *" name="rg" variant="outlined" />
                </>
              )}
              {chosedNationality === 'estrangeiro' && (
                <Input
                  label="Passaporte *"
                  name="document"
                  variant="outlined"
                />
              )}
            </div>
            <Input label="E-mail *" name="email" variant="outlined" />
          </fieldset>
          <input
            type="hidden"
            className="esitef-cardnumber"
            defaultValue={creditCard.cardNumber}
          />
          <input
            type="hidden"
            className="esitef-cardexpirymonth"
            defaultValue={creditCard.expirationMonth}
          />
          <input
            type="hidden"
            className="esitef-cardexpiryyear"
            defaultValue={creditCard.expirationYear}
          />
          <input
            type="hidden"
            className="esitef-cardsecuritycode"
            defaultValue={creditCard.securityCode}
          />
        </ContainerCardFields>

        <fieldset className="fieldset-container">
          <h1>Endereço de cobrança</h1>
          <Scope path="address">
            <div className="grid-container">
              <Input
                label="CEP *"
                name="zipCode"
                InputProps={{
                  inputComponent: ZipCodeMask as any
                }}
                onChange={(event: any) => handleCEP(event, 'owner')}
                variant="outlined"
              />
            </div>
            <Input
              label="Endereço *"
              name="street"
              InputLabelProps={{
                shrink: shrinked.street || touched.street,
                focused: touched.street
              }}
              onFocus={() => setTouched({ ...touched, street: true })}
              onBlur={() => setTouched({ ...touched, street: false })}
              onChange={(event: any) =>
                event.target.value === ''
                  ? setShrinked({ ...shrinked, street: false })
                  : setShrinked({ ...shrinked, street: true })
              }
              style={{ marginBottom: 24 }}
              variant="outlined"
            />
            <div className="grid-container">
              <Input label="Número" name="number" variant="outlined" />
              <Input
                label="Complemento (opcional)"
                name="complement"
                variant="outlined"
              />
              <Input
                label="Bairro *"
                name="district"
                InputLabelProps={{
                  shrink: shrinked.district || touched.district,
                  focused: touched.district
                }}
                onFocus={() => setTouched({ ...touched, district: true })}
                onBlur={() => setTouched({ ...touched, district: false })}
                onChange={(event: any) =>
                  event.target.value === ''
                    ? setShrinkedB({ ...shrinked, district: false })
                    : setShrinkedB({ ...shrinked, district: true })
                }
                variant="outlined"
              />
              <Input
                label="Cidade *"
                name="city"
                InputLabelProps={{
                  shrink: shrinked.city || touched.city,
                  focused: touched.city
                }}
                onFocus={() => setTouched({ ...touched, city: true })}
                onBlur={() => setTouched({ ...touched, city: false })}
                onChange={(event: any) =>
                  event.target.value === ''
                    ? setShrinked({ ...shrinked, city: false })
                    : setShrinked({ ...shrinked, city: true })
                }
                variant="outlined"
              />
              <Input
                label="Estado *"
                name="state"
                InputLabelProps={{
                  shrink: shrinked.state || touched.state,
                  focused: touched.state
                }}
                onFocus={() => setTouched({ ...touched, state: true })}
                onBlur={() => setTouched({ ...touched, state: false })}
                onChange={(event: any) =>
                  event.target.value === ''
                    ? setShrinked({ ...shrinked, state: false })
                    : setShrinked({ ...shrinked, state: true })
                }
                variant="outlined"
              />
            </div>
          </Scope>
        </fieldset>

        <h1 style={{ marginTop: '0.5rem' }}>Dados do beneficiário</h1>
        <p>
          Insira os dados da pessoa que irá <strong>utilizar</strong> o serviço
          contratado.
        </p>

        <div
          className={`${
            fullDiscountVoucher ? 'radio-wrapper hide' : 'radio-wrapper'
          }`}
        >
          <div className="radio-label">
            <input
              name="copy-data"
              type="radio"
              value="copy"
              className="form-control"
              onChange={handleCopy}
              checked={copy}
            />
            <label>Copiar dados do titular do cartão</label>
          </div>
        </div>
        <div>
          <fieldset className="fieldset-container">
            <br />
            <div className="grid-container">
              <Input
                label="Nome *"
                name="firstNameB"
                variant="outlined"
                InputLabelProps={{
                  shrink: shrinkedC.firstNameB || touched.firstNameB,
                  focused: touched.firstNameB
                }}
              />
              <Input
                label="Sobrenome *"
                name="lastNameB"
                variant="outlined"
                InputLabelProps={{
                  shrink: shrinkedC.lastNameB || touched.lastNameB,
                  focused: touched.lastNameB
                }}
              />
              <Input
                label="Data de nascimento *"
                name="birthDateB"
                value={birthDateB}
                onChange={(e: any) => setBirthDateB(DateM(e.target.value))}
                InputLabelProps={{
                  shrink: shrinkedC.birthDateB || touched.birthDateB,
                  focused: touched.birthDateB
                }}
                variant="outlined"
              />
              <SelectInput
                label="Sexo"
                name="genderB"
                value={genderB}
                onChange={handleGenderB}
                valueList={['Masculino', 'Feminino']}
              />
              <Input
                label="DDD + Telefone *"
                name="phoneNumberB"
                value={phoneNumberB}
                onChange={(e: any) => setPhoneNumberB(Phone(e.target.value))}
                InputLabelProps={{
                  shrink: shrinkedC.phoneNumberB || touched.phoneNumberB,
                  focused: touched.phoneNumberB
                }}
                InputProps={{
                  inputComponent: PhoneNumberMask as any
                }}
                variant="outlined"
              />

              <SelectInput
                label="Nacionalidade"
                name="nationalityB"
                value={nationalityB}
                valueList={['Brasileiro', 'Estrangeiro']}
                onChange={handleNationalityDocumentB}
              />
              {chosedNationality === 'brasileiro' && (
                <>
                  <Input
                    label="CPF *"
                    name="documentB"
                    value={documentB}
                    onChange={(e: any) => setDocumentB(Cpf(e.target.value))}
                    InputLabelProps={{
                      shrink: shrinkedC.documentB || touched.documentB,
                      focused: touched.documentB
                    }}
                    variant="outlined"
                  />
                  <Input
                    label="RG *"
                    name="rgB"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: shrinkedC.rgB || touched.rgB,
                      focused: touched.rgB
                    }}
                  />
                </>
              )}
              {chosedNationality === 'estrangeiro' && (
                <Input
                  label="Passaporte *"
                  name="documentB"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: shrinkedC.documentB || touched.documentB,
                    focused: touched.documentB
                  }}
                />
              )}
            </div>
            <Input
              label="E-mail *"
              name="emailB"
              variant="outlined"
              InputLabelProps={{
                shrink: shrinkedC.emailB || touched.emailB,
                focused: touched.emailB
              }}
            />
          </fieldset>
          {Number(props.numberProductPlan) === 1 && (
            <>
              <h1>Endereço de entrega</h1>
              <p>
                O <strong>Kit de coleta</strong> será enviado de acordo com as
                informações fornecidas abaixo.{' '}
                <strong>Preencha os campos com atenção:</strong>
              </p>
              <br />
              <fieldset className="fieldset-container">
                <Scope path="addressBeneficiary">
                  <div className="grid-container">
                    <Input
                      label="CEP *"
                      name="zipCode"
                      InputProps={{
                        inputComponent: ZipCodeMask as any
                      }}
                      onChange={(event: any) => handleCEP(event, 'beneficiary')}
                      variant="outlined"
                    />
                  </div>
                  <Input
                    label="Endereço *"
                    name="street"
                    InputLabelProps={{
                      shrink: shrinkedB.street || touched.street,
                      focused: touched.street
                    }}
                    onFocus={() => setTouched({ ...touched, street: true })}
                    onBlur={() => setTouched({ ...touched, street: false })}
                    onChange={(event: any) =>
                      event.target.value === ''
                        ? setShrinkedB({ ...shrinkedB, street: false })
                        : setShrinkedB({ ...shrinkedB, street: true })
                    }
                    style={{ marginBottom: 24 }}
                    variant="outlined"
                  />
                  <div className="grid-container">
                    <Input label="Número" name="number" variant="outlined" />
                    <Input
                      label="Complemento (opcional)"
                      name="complement"
                      variant="outlined"
                    />
                    <Input
                      label="Bairro *"
                      name="district"
                      InputLabelProps={{
                        shrink: shrinkedB.district || touched.district,
                        focused: touched.district
                      }}
                      onFocus={() => setTouched({ ...touched, district: true })}
                      onBlur={() => setTouched({ ...touched, district: false })}
                      onChange={(event: any) =>
                        event.target.value === ''
                          ? setShrinkedB({ ...shrinkedB, district: false })
                          : setShrinkedB({ ...shrinkedB, district: true })
                      }
                      variant="outlined"
                    />
                    <Input
                      label="Cidade *"
                      name="city"
                      InputLabelProps={{
                        shrink: shrinkedB.city || touched.city,
                        focused: touched.city
                      }}
                      onFocus={() => setTouched({ ...touched, city: true })}
                      onBlur={() => setTouched({ ...touched, city: false })}
                      onChange={(event: any) =>
                        event.target.value === ''
                          ? setShrinkedB({ ...shrinkedB, city: false })
                          : setShrinkedB({ ...shrinkedB, city: true })
                      }
                      variant="outlined"
                    />
                    <Input
                      label="Estado *"
                      name="state"
                      InputLabelProps={{
                        shrink: shrinkedB.state || touched.state,
                        focused: touched.state
                      }}
                      onFocus={() => setTouched({ ...touched, state: true })}
                      onBlur={() => setTouched({ ...touched, state: false })}
                      onChange={(event: any) =>
                        event.target.value === ''
                          ? setShrinkedB({ ...shrinkedB, state: false })
                          : setShrinkedB({ ...shrinkedB, state: true })
                      }
                      variant="outlined"
                    />
                  </div>
                </Scope>
              </fieldset>
            </>
          )}
        </div>
        <br />
        <label className="flex check-box terms">
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={handleCheckboxChange}
          />
          {localStorage.getItem('selectedProduct') ===
          '5e220a3a-eca7-408e-848b-eb04c73ffe9f' ? (
            <div>
              Termos do{' '}
              <Link
                className="link"
                to="/Termos/CONTRATO_PREDICTA_ONE_v01.pdf"
                target="_blank"
              >
                {' '}
                contrato de prestação{' '}
              </Link>
              de serviços.
            </div>
          ) : localStorage.getItem('selectedProduct') ===
            '9ab46936-b35e-4741-996f-b18aebdca53e' ? (
            <div>
              Termos do{' '}
              <Link
                className="link"
                to="/Termos/CONTRATO_PREDICTA_ONE_v01.pdf"
                target="_blank"
              >
                {' '}
                contrato de prestação{' '}
              </Link>
              de serviços.
            </div>
          ) : (
            <div>
              Termos do{' '}
              <Link
                className="link"
                to="/Termos/CONTRATO_PREDICTA_INFINITY_v01.pdf"
                target="_blank"
              >
                {' '}
                contrato de prestação{' '}
              </Link>
              de serviços.
            </div>
          )}
        </label>
        <Button type="submit" disabled={!termsAccepted}>
          <span style={{ fontSize: '18px' }}>Confirmar compra</span>
        </Button>
      </Form>
    </Container>
  );
};

export default PaymentForm;
