import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { CONFIG } from '../../../../config/env';
import AppCookies from '../../../../utils/cookies';
import { peApi, qasApi } from '../utils/api';
interface IAuthError {
  code: string;
  message?: string;
}

interface AuthContextData {
  access_token: string;
  patient_token: string;
  service_id: string;
  source_platform: string;
  token: string;
  errors: IAuthError;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [sourcePlatform, setSourcePlatform] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [requestToken, setRequestToken] = useState('');
  const [authErrors, setAuthErrors] = useState<IAuthError>({} as IAuthError);

  const token = `Bearer ${String(localStorage.getItem('api_token'))}`;
  const tokenBuy = `Bearer ${String(localStorage.getItem('api_token_buy'))}`;
  const tokenPatient = `Bearer ${AppCookies.get('api_patientToken')}`;

  const handleAuthItems = useCallback(async () => {
    const service_id = CONFIG.PAYMENT_SERVICE_ID;
    const source_platform = CONFIG.SOURCE_PLATFORM;

    if (!service_id) {
      setAuthErrors({ code: '1653' });
    }

    if (!source_platform) {
      setAuthErrors({ code: '1472' });
    } else {
      setSourcePlatform(source_platform);
    }

    await peApi
      .post('products', { service_id })
      .then((response) => {
        if (response.data.service_id) {
          setServiceId(response.data.service_id);
        } else {
          setAuthErrors({ code: '1743' });
        }
      })
      .catch((error) =>
        setAuthErrors({ code: '1892', message: error.response.data.error })
      );

    await qasApi
      .get('/users', {
        headers: {
          'x-access-token': `${token}`,
          'x-access-patient-token': `${tokenPatient}`
        }
      })
      .then((response) => {
        if (token && response.data.user) {
          setAccessToken(tokenBuy);
        }
      })
      .catch((error) => {
        console.log(error);
        setAuthErrors({ code: '1959', message: error.response.data.error });
      });
  }, []);

  useEffect(() => {
    handleAuthItems();
  }, [handleAuthItems]);

  return (
    <AuthContext.Provider
      value={{
        access_token: token,
        patient_token: tokenPatient,
        service_id: serviceId,
        errors: authErrors,
        source_platform: sourcePlatform,
        token: requestToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
