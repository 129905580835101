import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SideBar from '../../components/SideBar';
import { ScheduleRootState, UserInfo } from '../../store/redux';
import UserIcon from '../../ui/UserIcon';
import { UserDataContent, SideBarArea, UserDataArea, Separator, Profile, ProfilePic, ProfileForm } from './styles';
import { useForm } from 'react-hook-form';
import { updateUserDetail, uploadPatientProfileImg } from '../../utils/services';
import { ButtonUploadFile } from '../../components/ButtonUploadFile';
import Load from '../../ui/SimpleLoad';
import { Cpf, DateM, Phone } from '../../utils/masks';
export default function UserData(): ReactElement {
  const userInfo: UserInfo = (useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user));
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState('');
  const [erros, setErros] = useState([]);

  const { handleSubmit } = useForm({});

  const handleProfileImgUpload = async (e: any | undefined): Promise<void> => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append('file', file);
    formData.append('documentType', 'PROFILE_PIC');
    await uploadPatientProfileImg(formData);
    window.location.reload();
  };

  const onSubmitForm = async (form: any) => {
    setLoading(true);
    setErro('');
    setErros([]);
    form.birthDate = form.birthDate?.split('/').reverse().join('-');
    const retUpdate = await updateUserDetail(form);
    if ('erro' in retUpdate) {
      setErro(retUpdate.erro);
      setErros(retUpdate.erros);
    }
    setLoading(false);
  };

  const [userForm, setUserForm] = useState({
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    phone: userInfo.phone,
    sex: userInfo.sex,
    birthDate: userInfo.birthDate
  });

  useEffect(() => {
    const userForm = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      phone: userInfo.phone && Phone(userInfo.phone),
      sex: userInfo.sex,
      birthDate: userInfo.birthDate
    };
    setUserForm(userForm);
  }, [userInfo]);

  return (
    <UserDataContent>
      <SideBarArea>
        <SideBar />
      </SideBarArea>
      <UserDataArea>
        <h1> Meus dados</h1>
        <Separator />
        {loading
          ? (
            <div className="load">
              <Load top='0px' left='50%' color='#009DFF' />
            </div>
          )
          : (
            <Profile>
              <ProfilePic>
                <UserIcon src={
                  // userInfo.imgThumb ??
                  ''
                } size={80} margin={'0 0 8px 0'} />
                <ButtonUploadFile
                  showload={false}
                  labelUpload={'Trocar foto'}
                  labelRemove={'Trocar foto'}
                  handleFileUploadSelected={e => handleProfileImgUpload(e)}
                />
              </ProfilePic>
              <ProfileForm>
                <>
                  {erro && (
                    <p className="error">{erro}</p>
                  )}
                  {erros?.map((erro: string, index: number): ReactElement => {
                    return (
                      <p className="error-list" key={index}>{erro}</p>
                    );
                  })}
                  <form onSubmit={handleSubmit(() => onSubmitForm(userForm))}>
                    <div className="flex">
                      <div className="form-group">
                        <input className="form-control" type="text" placeholder="Nome" required readOnly value={userForm?.firstName} />
                        <label className="form-label">Nome</label>
                      </div>
                      <div className="form-group">
                        <input className="form-control" type="text" placeholder="Sobrenome" required readOnly value={userForm?.lastName} />
                        <label className="form-label">Sobrenome</label>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="form-group">
                        <input className="form-control" type="text" placeholder="E-mail" readOnly required
                          value={userInfo?.email} />
                        <label className="form-label">E-mail</label>
                      </div>
                      <div className="form-group">
                        <input className="form-control" type="text" placeholder="Telefone" required readOnly value={userForm?.phone} />
                        <label className="form-label">Telefone</label>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="form-group">
                      <input className="form-control" type="text" placeholder="Sexo" readOnly required
                          value={userInfo?.sex === 'M' ? 'Masculino' : 'Feminino'} />
                        {/* <select className="form-control" placeholder="sexo" required value={userForm?.sex}
                          onChange={(e) => setUserForm(prevstate => ({ ...prevstate, sex: e.target.value }))}>
                          <option value="">Sexo</option>
                          <option value="F">Feminino</option>
                          <option value="M">Masculino</option>
                        </select> */}
                        <label className="form-label">Sexo</label>
                      </div>
                      <div className="form-group">
                        <input className="form-control" type="text" placeholder="Data de nascimento" readOnly required
                          value={userForm?.birthDate?.split('-').reverse().join('/')} />
                        <label className="form-label">Data de nascimento</label>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="form-group">
                        <input className="form-control" type="text" placeholder="Nacionalidade" readOnly required
                          value={userInfo.document.length === 11 ? 'Brasileiro' : 'Estrangeiro'} />
                        <label className="form-label">Nacionalidade</label>
                      </div>
                      <div className="form-group">
                        <input className="form-control" type="text" placeholder="Documento" readOnly required
                          value={userInfo.document.length === 11 ? Cpf(userInfo.document) : userInfo.document} />
                        <label className="form-label">Documento</label>
                      </div>
                    </div>
                    <button className="btn">Salvar</button>
                  </form>
                </>
              </ProfileForm>
            </Profile>)}
      </UserDataArea>
    </UserDataContent>
  );
}
