import ClearIcon from '@material-ui/icons/Clear';
import { ReactElement, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { LOGIN_PURCHASE } from '../../store/redux';
import Load from '../../ui/SimpleLoad';
import AppCookies from '../../utils/cookies';
import { getTokenAuth, getUserData, resetPassword } from '../../utils/services';
import { FormWrapper, Login, Modal, ModalBkg, Register } from './styles';

type props = {
  open: boolean
  setOpen: Function | any
};

export default function ModalLogin(props: props): ReactElement {
  const [formCpf, setformCpf] = useState('');
  const [formPassword, setformPassword] = useState('');
  const [formEmail, setformEmail] = useState('');
  const [erro, setErro] = useState('');
  const [erros, setErros] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [step, setStep] = useState('login');

  // eslint-disable-next-line no-lone-blocks
  {
    props.open
      ? document.body.classList.add('modal-open')
      : document.body.classList.remove('modal-open');
  }

  const submitHandle = async (): Promise<void> => {
    if (!formCpf && !formPassword) {
      setErro('Preencha as informações de login');
    } else if (!formCpf) {
      setErro('Insira o documento');
    } else if (!formPassword) {
      setErro('Insira a senha');
    } else {
      setLoading(true);
      const loginResponse = await getTokenAuth(formCpf, formPassword);
      // window.localStorage.setItem('selectedProduct', '1');
      if ('token' in loginResponse && 'patientToken' in loginResponse) {
      AppCookies.set('api_token', loginResponse.token);
      AppCookies.set('api_patientToken', loginResponse.patientToken);

        dispatch({ type: LOGIN_PURCHASE, number: 200, search: loginResponse.token, param: loginResponse.refreshToken });

        document.body.classList.remove('modal-open');
        // window.localStorage.setItem('selectedProduct', '1');

        await getUserData().then((detailed) => {
          if (detailed.eligibility) {
            window.location.assign('/dashboard');
          } else {
            window.location.assign('/contratar');
          }
        });
        // const detailed = await getUserData();
        // if (detailed.medicalRecord && detailed.eligibility) {
        //   dispatch({ type: LOGIN_PURCHASE, number: 200, search: loginResponse.token, param: loginResponse.refreshToken });
        //   document.body.classList.remove('modal-open');
        //   window.location.assign('/dashboard');
        // } else if (detailed.elegibility || !detailed.document) {
        //   setErro('Erro inesperado, tente novamente mais tarde.');
        // } else {
        //   setErro('Usuário não possui elegibilidade ao produto.');
        // }
      } else {
        setErro(loginResponse.erro);
        setErros(loginResponse.erros);
      }
      setLoading(false);
    }
  };

  async function handleStep(stepName: string): Promise<any> {
    setErro('');
    if (stepName === 'sendEmail') {
      if (!formEmail) {
        setErro('Insira o CPF');
      } else {
        setErro('');
        setLoading(true);
        const resetResponse = await resetPassword(formEmail);
        if ('message' in resetResponse) {
          setErro(resetResponse.message);
        } else {
          setMessage(resetResponse.data.message);
          setStep(stepName);
        }
        setLoading(false);
      }
    } else {
      setformEmail('');
      setStep(stepName);
    }
  }
  return (
    <ModalBkg open={props.open} onClick={() => props.setOpen(false)}>
      <Modal onClick={e => { e.stopPropagation(); }}>
        <Register>
          <div>
            <h1>Olá! Novo por aqui?</h1>
            <p>
              Cadastre-se para ter acesso aos <br /> serviços do Einstein Conecta
            </p>
          </div>
          <Link id="btn-003" className="button" to="/cadastro" onClick={() => {
            const tagManagerArgs = {
              gtmId: 'GTM-TDPD3K8',
              dataLayer: {
                event: 'Einstein-Predicta',
                categoria: 'Entrar',
                acao: 'step-2',
                rotulo: 'Cadastrar'
              }
            };

            TagManager.initialize(tagManagerArgs);
          }}>Cadastrar</Link>
        </Register>
        <Login>
          <div className="flex">
            <button onClick={() => props.setOpen(false)}>
              <ClearIcon />
            </button>
          </div>
          {step === 'login'
            ? (
              <>
                {loading
                  ? (
                    <Load left="45%" top="35%" />)
                  : (
                    <div className="wrapper">
                      <div className="height">
                        <h1>Acesse sua conta</h1>
                        <FormWrapper>
                          {erro && <p className="error">{erro}</p>}
                          {erros?.map((erro: string, index: number): ReactElement => {
                            return (
                              <p className="error-list" key={index}>{erro}</p>
                            );
                          })}
                          {/* <input placeholder="CPF ou Passaporte" type="text" value={formCpf} onChange={(e) => setformCpf(Cpf(e.target.value))} /> */}

                          <input placeholder="CPF ou Passaporte" type="text" value={formCpf} onChange={(e) => {
                            const inputValue = e.target.value;
                            // if (inputValue.length === 11) {
                            //   const formatedCpf = Cpf(inputValue);
                            //   setformCpf((formatedCpf));
                            // }
                            // if (inputValue.length < 11) {
                            setformCpf(inputValue);
                            // }
                          }}></input>

                          <input placeholder="Senha" type="password" onChange={(e) => setformPassword(e.target.value)} />
                        </FormWrapper>
                      </div>
                      <button id="btn-004" className="login" onClick={async () => {
                        submitHandle();
                        const tagManagerArgs = {
                          gtmId: 'GTM-TDPD3K8',
                          dataLayer: {
                            event: 'Einstein-Predicta',
                            categoria: 'Entrar',
                            acao: 'step-2',
                            rotulo: 'Entrar'
                          }
                        };

                        TagManager.initialize(tagManagerArgs);
                      }}>Entrar</button>
                      <button id="btn-005" className="link" onClick={async () => handleStep('forgot')}>Esqueci minha senha</button>
                    </div>
                  )
                }
              </>
            )
            : step === 'forgot'
              ? (
                <>
                  {loading
                    ? (
                      <Load left="45%" top="35%" />)
                    : (
                      <div className="wrapper">
                        <div className="height mb-50">
                          <h1>Recuperação de senha</h1>
                          <p className="forgot">
                            Insira o e-mail vinculado à sua conta para enviarmos um
                            <br /> link para redefinição da sua senha
                          </p>
                          <FormWrapper>
                            {erro && <p className="error">{erro}</p>}
                            <input placeholder="E-mail, CPF ou passaporte" type="text" value={formEmail} onChange={(e) => setformEmail(e.target.value)}></input>
                          </FormWrapper>
                        </div>
                        <button className="login" onClick={async () => handleStep('sendEmail')}>Recuperar Senha</button>
                        <button className="link" onClick={async () => handleStep('login')}>Voltar</button>
                      </div>
                    )
                  }
                </>
              )
              : step === 'sendEmail' &&
              (
                <>
                  {loading
                    ? (
                      <Load left="45%" top="35%" />)
                    : (
                      <div className="wrapper">
                        <div className="height">
                          <h1>Recuperação de senha</h1>
                          <FormWrapper>
                            <p className="forgot">
                              {message}.
                            </p>
                            <p className="forgot">
                              As instruções para redefinição de senha foram
                              <br />
                              encaminhadas para seu e-mail.
                            </p>
                          </FormWrapper>
                        </div>
                        <button className="login" onClick={async () => handleStep('login')}>Voltar para o inicio</button>
                        <button className="link" onClick={async () => handleStep('sendEmail')}>Reenviar e-mail</button>
                      </div>
                    )
                  }
                </>
              )
          }

        </Login>
      </Modal>
    </ModalBkg>
  );
}
