 /* eslint-disable */
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ScheduleRootState, UserInfo } from '../../store/redux';

import { IconNotification, IconLogOff } from '../../assets/icons';
import userImage from '../../assets/user-image.png';

import {
  UserInfoWrapper,
  Notifications,
  UserInfoImage,
  UserInfoName,
  UserInfoCard,
  Toggle
} from './styles';
import { moveToLogin } from '../../utils/moveToLogin';

export default function UserInfos(): ReactElement {
  const user: UserInfo = (useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user));
  const [isOpen, setIsOpen] = useState(false);
  const toggleUserCard = (): void => {
    setIsOpen(!isOpen);
  };
  function logoff(): void {
    localStorage.clear();
    moveToLogin(true)
  }
  return (
    <UserInfoWrapper>
      {/* <Notifications>
                <IconNotification />
            </Notifications> */}
      <UserInfoName>{`${user.firstName} ${user.lastName}`}</UserInfoName>
      <UserInfoImage src={
        // user.imgThumb ??
        userImage} size={3} margin={'0 1rem 0 0'} alt={user.firstName} />

      <Toggle onClick={toggleUserCard} isOpen={isOpen}></Toggle>

      {isOpen &&
        <UserInfoCard>
          <UserInfoImage src={
            // user.imgThumb
            // ??
            userImage} size={4} margin={'0 0 1rem 0'} alt={user.firstName} />
          <UserInfoName>{`${user.firstName} ${user.lastName}`}</UserInfoName>
          {/* {user.owner && <span className="user-info--level">Principal</span>} */}
          <span className="user-info--level">Principal</span>
          <Link to="/meus-dados" className="user-info--manage-btn">Gerenciar Conta</Link>
          <Link to="/" className="user-info--logoff" onClick={() => logoff()}>
            <IconLogOff /> Fazer Logoff
          </Link>
        </UserInfoCard>
      }
    </UserInfoWrapper>
  );
}
