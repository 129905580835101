/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable semi */
import { ReactElement, useState, useEffect } from 'react';
import { ptBR } from 'date-fns/locale';
import CalendarSchedule from '../CalendarSchedule';
import { getDays, getHours, createSchedule, getClinics, getUserData } from '../../utils/services';

import { useDispatch, useSelector } from 'react-redux';
import {
  CLOSE_MODAL,
  RESET_NEW_SCHEDULE,
  NEW_ONLINE_SCHEDULE,
  NEW_PRESENTIAL_SCHEDULE,
  NEW_SCHEDULE_STEP_TIME,
  NEW_SCHEDULE_SET_TIME,
  ScheduleRootState,
  ScheduleInfo,
  CONFIRMATION_STEP,
  NEW_SCHEDULE,
  NEW_SAMPLE_COLLECTION,
  NEW_STATE_COLLECTION,
  NEW_PRESENTIAL_SCHEDULE_DAY,
  NEW_SCHEDULE_SET_CLINIC,
  SCHEDULE_SEND,
  SCHEDULE_ERROR,
  OPEN_MODAL,
  RESCHEDULE,
  SET_RELOAD,
  NEW_SINGLE_APPOINTMENT,
  CONFIRMATION_STEP_INFO,
  UserInfo
} from '../../store/redux';

import {
  IconScheduleOnline,
  IconSchedulePresential,
  IconClose,
  ArrowBack,
  IconScheduleUser,
  IconScheduleCheckupGenetic,
  IconScheduleStep,
  IconScheduleAppointment,
  IconCalendar,
  IconClock,
  IconCheckBorder
} from '../../assets/icons';

import {
  ModalBody,
  ButtonBack,
  ButtonNext,
  ModalProductText,
  ModalHeader,
  ModalContent,
  UserWrapper,
  ModalNewSchedule,
  ScheduleModalButton,
  HoursWrapper,
  HoursButton,
  ScheduleInfoBox,
  ClinicList,
  ClinicItem,
  ClinicItemName,
  ClinicItemAddress,
  Load,
  PriceLabel
} from './styles';
import userImage from '../../assets/user-image.png';

import { format, parseISO } from 'date-fns';
import PaymentForm from '../../pages/Purchase/Single';
import AppProvider from '../../pages/Purchase/Payment/hooks';
import About from '../../assets/Site/about.png';
import { unitsPredicta } from '../../utils/units';
import { getUnitsList } from '../../services/schedule/schedule.service';
import DateUtils from '../../utils/date';
import { number } from 'joi';
import { AxiosResponse } from 'axios';

type ModalProps = {
  show: boolean
};

type SwitchProps = {
  step: string
};

export default function ModalSchedule({ show }: ModalProps): ReactElement {
  const dispatch = useDispatch();
  const user = useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user);
  // const [lastStep, setLastStep] = useState<any>({});
  const lastStep = JSON.parse(localStorage.getItem('lastStep') ?? '[]');
  const [clinicFullName, setClinicFullName] = useState<string>('');
  const step = useSelector<ScheduleRootState, string>(scheduleReducer => scheduleReducer.step);
  const reload = useSelector<ScheduleRootState, boolean>(scheduleReducer => scheduleReducer.reload);
  const userDataLoaded = useSelector<ScheduleRootState, boolean>(scheduleReducer => scheduleReducer.userDataLoaded);

  const [dateSelected, setDateSelected] = useState<Date>(new Date());

  const handleReschedule = async (idSchedule: any): Promise<any> => {
    dispatch({ type: RESCHEDULE, rescheduleId: idSchedule });
    dispatch({ type: OPEN_MODAL });
  };

  const newSchedule = useSelector<ScheduleRootState, ScheduleInfo | undefined>(scheduleReducer => scheduleReducer.newSchedule);

  const nextStep = user.step === 0 ? 'Consulta pré-exame' : user.step === 1 ? 'Coleta' : user.step === 2 ? 'Consulta pós-exame' : 'Acompanhamento';

  const actualStep = newSchedule?.rescheduleId ? user.step : user.step + 1;

  const [selectedTime, setSelectedTime] = useState(newSchedule?.appointmentData?.scheduleId);

  const [selectedPeriod, setSelectedPeriod] = useState(newSchedule?.time);

  const [scheduleDays, setScheduleDays] = useState<any>([]);
  const [scheduleHours, setScheduleHours] = useState<any>([]);
  const [clinicList, setClinicList] = useState<any>([]);
  const [selectedClinicObject, setSelectedClinicObject] = useState<any>({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<any>(null);

  const [selectedClinic, setSelectedClinic] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');

  const [scheduleModality, setScheduleModality] = useState<string>('');

  useEffect(() => {
    console.log(user);
  }, [user]);

  const loadClinics = async (): Promise<any> => {
    setIsLoading(true);
    const getClinicsResponse = await getUnitsList();
    setClinicList(getClinicsResponse);
    setIsLoading(false);
    return getClinicsResponse;
  };

  const loadDays = async (queryString: any): Promise<any> => {
    setIsLoading(true);
    const getDaysResponse = await getDays(queryString);
    if (!getDaysResponse) dispatch({ type: SCHEDULE_ERROR });
    setScheduleDays(getDaysResponse);
    setIsLoading(false);
    return scheduleDays;
  };

  const loadHours = async (queryString: any): Promise<any> => {
    setIsLoading(true);
    const getHoursResponse = await getHours(queryString);
    if (!getHoursResponse) dispatch({ type: SCHEDULE_ERROR });
    setScheduleHours(getHoursResponse);
    // let period: any[];
    // const week = DateUtils.FormatWeek(new Date(dateSelected));
    // console.log(selectedClinic);
    // const clinicTurn = JSON.parse(clinicList.find((i: any) => i.id === parseInt(selectedClinic)).turn);
    // if (week === 'Segunda' || week === 'Terça' || week === 'Quarta' || week === 'Quinta' || week === 'Sexta') {
    //   period = [];
    //   clinicTurn['Seguda/Sexta'].map((i: any) => period.push({ period: i }))
    //   setScheduleHours(period);
    // } else if (week === 'Sabado') {
    //   period = [];
    //   clinicTurn.Sabado.map((i: any) => period.push({ period: i }));
    //   setScheduleHours(period);
    // } else if (week === 'Domingo') {
    //   period = [];
    //   clinicTurn['Domingo/Feriado'].map((i: any) => period.push({ period: i }));
    //   setScheduleHours(period);
    // }
    setIsLoading(false);
    return scheduleHours;
  };

  const handleDays = async (type: any, modality: string, IdClinic?: string): Promise<any> => {
    dispatch({ type: type, local: IdClinic, modality: modality });
    setScheduleModality(modality);
    const queryObj = {
      medicalRecord: user.prontuario,
      unityId: String(IdClinic),
      modality: modality,
      resourceId: process.env.REACT_APP_RESOURCE_ID,
      stepId: actualStep
    };
    const clinic = clinicList.filter((item: any) => item.id.toString() === selectedClinic);
    setSelectedClinicObject(clinic);
    loadDays(queryObj);
  };

  const handleHours = async (): Promise<any> => {
    dispatch({ type: NEW_SCHEDULE_STEP_TIME });
    const queryObj = {
      medicalRecord: user.prontuario,
      unityId: selectedClinic,
      modality: scheduleModality,
      slotDate: newSchedule?.day,
      resourceId: process.env.REACT_APP_RESOURCE_ID,
      stepId: actualStep
    };
    // console.log(queryObj);
    loadHours(queryObj);
  };

  const handleClinics = async (type: any): Promise<any> => {
    dispatch({ type: type });
    const clinicsResponse = await loadClinics();
    setClinicList(clinicsResponse.filter((item: any) => item.active === true));
    return clinicsResponse;
  };
  const handleSelectedClinic = (clinicId: number): any => {
    setSelectedClinic(String(clinicId));
    dispatch({ type: NEW_SCHEDULE_SET_CLINIC, idLocal: String(clinicId) });
  };
  const handleSelectedState = (state: string): any => {
    switch (state) {
      case 'AL': return 'Alagoas';
      case 'AC': return 'Acre';
      case 'AP': return 'Amapá';
      case 'AM': return 'Amazonas';
      case 'BA': return 'Bahia';
      case 'CE': return 'Ceará';
      case 'DF': return 'Distrito Federal';
      case 'ES': return 'Espírito Santo';
      case 'GO': return 'Goías';
      case 'MA': return 'Maranhão';
      case 'MT': return 'Mato Grosso';
      case 'MS': return 'Mato Grosso do Sul';
      case 'MG': return 'Minas Gerais';
      case 'PA': return 'Pará';
      case 'PB': return 'Paraíba';
      case 'PR': return 'Paraná';
      case 'PE': return 'Pernambuco';
      case 'PI': return 'Piauí';
      case 'RJ': return 'Rio de Janeiro';
      case 'RN': return 'Rio Grande do Norte';
      case 'RS': return 'Rio Grande do Sul';
      case 'RO': return 'Rondônia';
      case 'RR': return 'Roraíma';
      case 'SC': return 'Santa Catarina';
      case 'SP': return 'São Paulo';
      case 'SE': return 'Sergipe';
      case 'TO': return 'Tocantins';

      default: return ''
    }
  };

  const handleBackButton = (step: string): any => {
    switch (step) {
      case 'choose-appointment-type': return CLOSE_MODAL;
      case 'schedule-single-appointment': return CLOSE_MODAL;
      case 'confirm-schedule': return NEW_SCHEDULE_STEP_TIME;
      case 'schedule-choose-time': return NEW_ONLINE_SCHEDULE;
      case 'schedule-choose-day': return NEW_SAMPLE_COLLECTION;
      case 'choose-clinic': return NEW_STATE_COLLECTION;
      case 'choose-state': return RESET_NEW_SCHEDULE;

      default: return RESET_NEW_SCHEDULE
    }
  };

  const submitSchedule = async (data: any): Promise<any> => {
    setIsLoading(true);

    await createSchedule({ ...data, modality: scheduleModality })
      .then(async (response: AxiosResponse) => {
        if (response.status === 200 || response.status === 201) {
          dispatch({ type: SCHEDULE_SEND })
        } else {
          if (response?.data?.source === 'Erro enviar e-mail') {
            dispatch({ type: SCHEDULE_SEND })
          }
          dispatch({ type: SCHEDULE_ERROR })
        }
        setIsLoading(false);
        setTimeout(async () => {
          await getUserData();
        }, 2000);
        return response;
      })
      .catch((error) => {
        if (error?.response?.data?.source === 'Erro enviar e-mail') {
          dispatch({ type: SCHEDULE_SEND })
        } else {
          dispatch({ type: SCHEDULE_ERROR })
        }
        setIsLoading(false);
        return error;
      });
  };

  useEffect(() => {
    if (Object.keys(lastStep).length > 0) {
      return;
    }

    const startTime = new Date().getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();

      if (now - startTime > 5000) {
        clearInterval(interval);
        return;
      }

      const value = localStorage.getItem('lastStep');

      if (value !== '' && value != null) {
        const data = JSON.parse(value);

        if (Object.keys(data).length === 0) {
          return;
        }

        // setLastStep(data);
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [userDataLoaded]);

  useEffect(() => {
    if (Object.keys(lastStep).length === 0) {
      return;
    }

    const clinic = unitsPredicta.unidades.filter((item: any) => item.id.toString() === lastStep?.unityId);
    const clinicName = clinic.length > 0 ? clinic[0].fullName : '';

    setClinicFullName(clinicName);
  }, [lastStep]);

  useEffect(() => {
    setSelectedTime(newSchedule?.appointmentData?.scheduleId);
  }, [newSchedule?.appointmentData?.scheduleId]);

  useEffect(() => {
    setSelectedPeriod(newSchedule?.time);
  }, [newSchedule?.time]);

  const formatDate = (date: string) => {
    const dateSplit = date.split('-');
    return `${dateSplit[2].trim()}/${dateSplit[1]}/${dateSplit[0]} - ${dateSplit[3]}`;
  };
  const formatAddress = (address: string) => {
    const formatedAddress = address.replace('-', '<br/>');
    return formatedAddress;
  };
  const uniqueState = clinicList.filter((value: any, index: any, self: any) => self.findIndex((m: any) => m.state === value.state) === index);

  const ModalSwitchContent = ({ step }: SwitchProps): ReactElement => {
    switch (step) {
      case 'choose-appointment-type':
        return (
          <ModalNewSchedule>
            {!newSchedule?.rescheduleId
              ? user.step === 1 && (
                <>
                  <p className="sample-warning">Não é necessário jejum para coleta do exame.</p>
                  {/* <p className="sample-warning sample-bottom">Nosso horário de atendimento para coleta laboratorial é de segunda-feira a sábado das 7h as 19h.</p> */}
                </>
              )
              : (
                user.step === 2 && (
                  <>
                    <p className="sample-warning">Não é necessário jejum para coleta do exame.</p>
                    {/* <p className="sample-warning sample-bottom">Nosso horário de atendimento para coleta laboratorial é de segunda-feira a sábado das 7h as 19h.</p> */}
                  </>
                )
              )
            }
            <span>Selecione</span>
            {!newSchedule?.rescheduleId
              ? user.step !== 1
                ? (
                  <ScheduleModalButton onClick={async () => dispatch({ type: NEW_SCHEDULE })}>
                    <IconScheduleOnline />Consulta
                  </ScheduleModalButton>
                )
                : (
                  <>
                    <ScheduleModalButton onClick={async () => handleClinics(NEW_STATE_COLLECTION)}>
                      <IconSchedulePresential />Coleta
                    </ScheduleModalButton>
                  </>
                )
              : (
                user.step !== 2
                  ? (
                    <ScheduleModalButton onClick={async () => dispatch({ type: NEW_SCHEDULE })}>
                      <IconScheduleOnline />Consulta
                    </ScheduleModalButton>
                  )
                  : (
                    <>
                      <ScheduleModalButton onClick={async () => handleClinics(NEW_STATE_COLLECTION)}>
                        <IconSchedulePresential />Coleta
                      </ScheduleModalButton>
                    </>
                  )
              )
            }
          </ModalNewSchedule>
        );
      case 'choose-online-or-presential':
        return (
          <ModalNewSchedule>
            <span>Selecione</span>
            <ScheduleModalButton onClick={async () => handleDays(NEW_ONLINE_SCHEDULE, 'online')}><IconScheduleOnline />Consulta Online</ScheduleModalButton>
          </ModalNewSchedule>
        );
      case 'schedule-choose-day':
        return (
          <ModalNewSchedule>
            {selectedClinicObject[0]
              ? (
                <span>
                  {selectedClinicObject[0].name} :<br />
                  {selectedClinicObject[0].address}<br /><br />
                </span>
              )
              : ('')
            }

            <span>Selecione a data</span>
            <CalendarSchedule functional={true} days={scheduleDays} dateSelected={dateSelected} setSelectedDate={setDateSelected} />
            <ButtonNext onClick={async () => handleHours()} disabled={newSchedule?.day === undefined}>Próximo</ButtonNext>
          </ModalNewSchedule>
        );
      case 'choose-state':
        return (
          <ModalNewSchedule>
            <span>Selecione o estado de sua preferência:</span>
            <ClinicList>
              {clinicList !== 'error' && uniqueState.map((clinic: any) => {
                return (
                  <ClinicItem key={clinic.state} onClick={() => { setSelectedState(clinic.state); handleClinics(NEW_SAMPLE_COLLECTION) }} className={selectedState === String(clinic.state) ? 'active' : ''}>
                    <ClinicItemName style={{ width: '54%' }}><IconSchedulePresential /><span style={{ width: 'auto' }}>{handleSelectedState(clinic.state)}</span></ClinicItemName>
                  </ClinicItem>
                );
              })}
            </ClinicList>
          </ModalNewSchedule>
        );
      case 'choose-clinic':
        return (
          <ModalNewSchedule>
            <span>Selecione a unidade de sua preferência:</span>
            <ClinicList>
              {clinicList !== 'error' && clinicList.filter((c: any) => c.state.toLowerCase() === selectedState.toLowerCase()).map((clinic: any) => {
                return (
                  <ClinicItem key={clinic.id} onClick={() => handleSelectedClinic(clinic.id)} className={selectedClinic === String(clinic.id) ? 'active' : ''}>
                    <ClinicItemName><IconSchedulePresential /><span>{clinic.name}</span></ClinicItemName>
                    <ClinicItemAddress dangerouslySetInnerHTML={{ __html: formatAddress(clinic.address) }} />
                  </ClinicItem>
                );
              })}
            </ClinicList>
            <ButtonNext onClick={async () => handleDays(NEW_PRESENTIAL_SCHEDULE_DAY, 'presential', selectedClinic)} disabled={!selectedClinic}>Próximo</ButtonNext>
          </ModalNewSchedule>
        );
      case 'schedule-choose-time':
        return (
          <ModalNewSchedule>
            <span>Selecione {actualStep === 2 ? 'o período' : 'a hora'} </span>
            <HoursWrapper>
              {
                scheduleHours.length > 0 && scheduleHours !== undefined
                  ? (scheduleHours?.map((hora: any, index: number) => {
                    return (
                      actualStep === 2
                        ? (
                          <HoursButton
                            onClick={() => dispatch({
                              type: NEW_SCHEDULE_SET_TIME,
                              time: hora.period
                            })}
                            className={selectedPeriod?.toString() === hora.period ? 'ativo' : ''}
                            key={index}
                          >
                            {hora.period}
                          </HoursButton>
                        )
                        : (
                          <HoursButton
                            onClick={() => dispatch({ type: NEW_SCHEDULE_SET_TIME, time: hora.slotInitHour, idAgenda: hora.scheduleId, idLocal: hora.locationId, resourceId: hora.resourceId, serviceId: hora.serviceId })}
                            className={selectedTime?.toString() === hora.scheduleId ? 'ativo' : ''}
                            key={hora.scheduleId}
                          >
                            {hora.slotInitHour.slice(0, 5)}
                          </HoursButton>
                        )
                    );
                  })
                  )
                  : (
                    <div>sem resultado</div>
                  )
              }
            </HoursWrapper>
            <ButtonNext onClick={() => dispatch({ type: CONFIRMATION_STEP })} disabled={newSchedule?.time === undefined}>Próximo</ButtonNext>
          </ModalNewSchedule>
        );
      case 'confirm-schedule':
        return (
          <ModalNewSchedule>
            <span>Confirme as informações</span>
            <ScheduleInfoBox>
              <div><IconScheduleUser />{user.firstName} {user.lastName}</div>
              <div>
                <IconScheduleCheckupGenetic />
                {user.products}
              </div>
              <div><IconScheduleStep />Etapa {actualStep === 11 ? 'Avulsa' : actualStep}</div>
              <div><IconScheduleAppointment />{!newSchedule?.rescheduleId ? nextStep : user.stepDescription}</div>
              {scheduleModality === 'online' ? <div><IconScheduleOnline />Teleconsulta</div> : <div><IconSchedulePresential />{selectedClinicObject[0].name ? selectedClinicObject[0].name : null}</div>}
              <div><IconCalendar />{new Date(String(newSchedule?.day)).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</div>
              <div><IconClock />{newSchedule?.time}</div>
            </ScheduleInfoBox>
            <ButtonNext onClick={async () => submitSchedule(newSchedule)}>Concluir</ButtonNext>
          </ModalNewSchedule>
        );
      case 'confirm-schedule-info':
        return (
          <ModalNewSchedule>
            <span>Confirme as informações</span>
            <ScheduleInfoBox>
              <div><IconScheduleUser />{user.firstName} {user.lastName}</div>
              <div>
                <IconScheduleCheckupGenetic />
                {user.products}
              </div>
              <div><IconScheduleStep />Etapa {lastStep === 11 ? 'Avulsa' : user.step}</div>
              <div><IconScheduleAppointment />{user.stepDescription}</div>
              {lastStep === '2' ? <div><IconSchedulePresential />{clinicFullName}</div> : <div><IconScheduleOnline />Teleconsulta</div>}
              <div><IconCalendar />{new Date(String(lastStep.registered).substring(0, 10)).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</div>
              <div><IconClock />{lastStep.registered.substring(11, 16)}</div>
            </ScheduleInfoBox>
          </ModalNewSchedule>
        );
      case 'schedule-done':
        setTimeout(() => {
          dispatch({ type: SET_RELOAD, load: !reload });
          location.reload();
        }, 2000);
        return (
          <ModalNewSchedule>
            <div className="flex">
              <IconCheckBorder />
              <h3>Agendamento efetuado <br /> com sucesso!</h3>
              <br />
              <hr />
              <br />
              <span>As informações do agendamento <br /> foram enviadas para o seu e-mail.</span>
            </div>
          </ModalNewSchedule>
        );
      case 'schedule-error':
        return (
          <ModalNewSchedule>
            <span>Houve um erro durante sua solicitação, tente novamente mais tarde.</span>
            <h2>{isError?.name}</h2>
            <p>{isError?.message}</p>
          </ModalNewSchedule>
        );

      case 'schedule-single-appointment':
        return (
          <AppProvider>
            <PriceLabel>
              <h4> Em até 6x de R$ 77,45 sem juros. </h4>
              <p> Total: R$ 464,69 </p>
            </PriceLabel>
            <PaymentForm product={3} />
          </AppProvider>
        );

      case 'schedule-single-appointment-success':
        return (
          <ModalNewSchedule>
            <div>
              <h2> Seu pedido foi realizado com sucesso!</h2>
              <br />
              <br />
              <h4>Após o recebimento do e-mail de confirmação de pagamento, você poderá agendar sua consulta com um médico geneticista. </h4>
              <br />
              <br />
              <img src={About} height={'300px'} width={'100%'} />
              <br />
              <h5>* Em caso de dúvida entre em contato nos canais de atendimento disponíveis em nosso site. </h5>
              <br />
            </div>
          </ModalNewSchedule>
        );

      default:
        return (
          <span>no return</span>
        );
    }
  };
  return (
    <ModalBody style={show ? { right: '0' } : { right: '-450px' }}>
      <ModalHeader>
        <UserWrapper>
          {/* <img src={user.imgThumb ?? userImage} alt="" /> */}
          <img src={userImage} alt="" />
          <div>
            <h2>{user.firstName} {user.lastName}</h2>
            <span>{user ? 'Titular' : 'Dependente'}</span>
          </div>
        </UserWrapper>
        <button className="modal--btn-close" onClick={() => dispatch({ type: CLOSE_MODAL })}><IconClose /></button>
      </ModalHeader>
      <ModalContent>
        <ButtonBack onClick={() => dispatch({ type: handleBackButton(step) })}>

          <ArrowBack /> {user.plan === 1 ? 'Consulta Avulsa' : 'Novo Agendamento'} </ButtonBack>
        {(((user.stepStatus === 'Agendada' || user.stepStatus === 'Em análise') && user.step !== 10)) && !newSchedule?.rescheduleId && step !== 'confirm-schedule-info'
          ? (
            <>
              {/* <ModalProductText bgColor="#00B193"><b>PR</b> Predicta</ModalProductText> */}
              <p className="step">Para realizar um novo agendamento, você deve concluir a etapa pendente.</p>
              <ScheduleInfoBox>
                <div><IconScheduleUser />{lastStep.doctor}</div>
                <div>
                  <IconScheduleCheckupGenetic />
                  {lastStep.stepStatusDescription}
                </div>
                {scheduleModality === 'online' ? <div><IconScheduleOnline />Teleconsulta</div> : <div><IconSchedulePresential />Presencial</div>}
                <div><IconClock />
                  {
                    user.step === 2
                      ? (
                        formatDate(lastStep.registered)
                      )
                      : (
                        `${format(parseISO(lastStep.registered), 'dd/MM/yyyy', { locale: ptBR })} às ${format(parseISO(lastStep.registered), 'H:mm', { locale: ptBR })}`
                      )
                  }
                </div>
              </ScheduleInfoBox>
              <hr />
              <p className="step">Se preferir, você pode reagendar a etapa atual</p>
              <br />
              <br />
              <ButtonNext onClick={() => { handleReschedule(lastStep.schedulingId); }}>Reagendar</ButtonNext>
            </>
          )
          : (
            <>
              {/* <ModalProductText bgColor="#00B193"><b>PR</b> Predicta</ModalProductText> */}
              <p className="nxt-step">
                {step === 'schedule-single-appointment' || user.plan === 1
                  ? 'Consulta Avulsa'
                  : actualStep !== 0 && `Etapa ${actualStep} - ${!newSchedule?.rescheduleId ? nextStep : user.stepDescription}`}
              </p>
              {isLoading ? <Load /> : <ModalSwitchContent step={step} />}
            </>
          )}
      </ModalContent>
    </ModalBody>
  );
}
