/* eslint-disable */
import React, { ReactElement, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Container } from './styles'

import {
  PURCHASE_PRONTUARIO,
  USER_PRODUCT,
  SET_LOAD,
  IS_USER_DATA_STALE,
  USER_DATA_LOADED
} from '../../store/redux';
import Load from '../../ui/SimpleLoad';

import AppCookies from '../../utils/cookies';

const token = `Bearer ${AppCookies.get('api_token')}`;
const tokenPatient = `Bearer ${AppCookies.get('api_patientToken')}`;

const config = {
  headers: {
    'x-access-token': token,
    'x-access-patient-token': tokenPatient
  }
};
const AuthUser: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>(JSON.parse(localStorage.getItem('user') ?? '[]'));
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)

  const history = useHistory();

  const baseUrl = process.env.REACT_APP_BASE_API;
  const getUserInfo = async (): Promise<any> => {
    const ret: any = await axios
      .get(`${baseUrl}/user/detailed`, config)
      .then(response => { return response.data.response; })
      .catch((_) => {
        history.replace('/contratar')
      });

    if (ret.eligibility === false) {
      history.replace('/contratar')
    }

    if (ret) {
      dispatch({
        type: PURCHASE_PRONTUARIO,
        name: ret.firstName,
        lastName: ret.lastName,
        param: ret.document,
        prontuario: ret.medicalRecord,
        email: ret.email,
        birthDate: ret.birthDate
      });

      dispatch({
        type: USER_PRODUCT,
        name: ret.firstName,
        param: ret.planDescription,
        lastName: ret.lastName,
        prontuario: ret.medicalRecord,
        state: {
          user: {
            eligibility: ret.eligibility,
            phone: ret.phone,
            email: ret.email,
            birthDate: ret.birthDate,
            sex: ret.sex,
            document: ret.document,
            nationality: ret.documentType.toLowerCase() === 'cpf' ? 'brasileiro' : 'estrangeiro',
            // imgThumb: ret.profile.image ?? '',
            questionnaire: ret.sendQuestionnaire,
            singleSchedule: ret.singleSchedule,
            plan: ret.planId,
            step: ret.stepStatusId,
            stepStatus: ret.stepStatus,
            stepDescription: ret.stepStatusDescription
          }
        }
      });

      const dataUser = {
        document: ret.document,
        documentType: ret.documentType,
        elegibility: ret.elegibility,
        name: `${ret.firstName} ${ret.lastName}`,
        firstName: ret.firstName,
        lastName: ret.lastName,
        email: ret.email,
        product: ret.planId,
        plan: ret.planDescription,
        prontuario: ret.medicalRecord,
        step: ret.stepStatusId ?? 0,
        stepStatus: ret.stepStatus,
        stepDescription: ret.stepStatusDescription,
        birthDate: ret.birthDate,
        phone: ret.phone,
        sex: ret.sex,
        // imgThumb: ret.profile.image ?? '',
        questionnaire: ret.sendQuestionnaire,
        singleSchedule: ret.singleSchedule
      };
      setUser(dataUser);

      dispatch({
        type: IS_USER_DATA_STALE,
        state: {
          isUserDataStale: true
        }
      });

      dispatch({
        type: USER_DATA_LOADED
      });

      // localStorage.setItem('user', JSON.stringify(dataUser));
      dispatch({ type: SET_LOAD, load: false });
    }
  };
  useEffect(() => {
    setLoading(true)
    getUserInfo().finally(()=>{
      setLoading(false)
    });
    // return () = {dispatch(load: true);}
  }, []);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return (
    <>
      {loading ? (
        <Container>
          <Load left="45%" top="35%" />
        </Container>
      ) : children}
    </>
  );
};

export default AuthUser
