import React, { ReactElement, useState, useEffect } from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import ScheduleCard from '../../components/ScheduleCard';
import NoSchedule from '../../ui/NoSchedule';
import { UserIcon } from '../../components/UserIcon/UserIcon';
import userImage from '../../assets/user-image.png';
import {
  Title,
  ButtonsWrapper,
  ScheduleContent,
  ScheduleContentMain,
  ScheduleWrapper,
  // HistoricWrapper,
  FilterWrapper,
  Label,
  Input,
  CheckList,
  FormWrapper,
  ButtonModal,
  ButtonsWrapperModal,
  Button,
  Load
} from './styles';

import { SET_LOAD, ScheduleRootState, UserInfo } from '../../store/redux';

import { IconFile, IconFilter } from '../../assets/icons';

import { ptBR } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { DateM } from '../../utils/masks';
import AppCookies from '../../utils/cookies';

const token = `Bearer ${AppCookies.get('api_token')}`;
const tokenPatient = `Bearer ${AppCookies.get('api_patientToken')}`;

const config = {
  headers: {
    'x-access-token': token,
    'x-access-patient-token': tokenPatient
  }
};

export default function Historic(): ReactElement {
  const dispatch = useDispatch();
  // const [buttonSlide, setButtonSlide] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const [fileOpen, setFileOpen] = useState(false); const [scheduleList, setScheduleList] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inputInitialDate, setInputInitialDate] = useState('');
  const [inputFinalDate, setInputFinalDate] = useState('');
  const [inputFinished, setInputFinished] = useState(true);
  const [inputRescheduled, setInputRescheduled] = useState(false);
  const [inputCanceled, setInputCanceled] = useState(false);

  const user: UserInfo = (useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user));
  const userDataLoaded = useSelector<ScheduleRootState, boolean>(scheduleReducer => scheduleReducer.userDataLoaded);

  const [bodySchedule, setBodySchedule] = useState({
    schedulingId: '',
    unityId: '',
    locationId: '',
    resourceId: '',
    serviceId: '',
    medicalRecord: user.prontuario,
    initialDate: '',
    finalDate: '',
    registerQuantity: null,
    lastRegister: null,
    schedulingType: null,
    futureScheduling: false,
    statusList: ['A']
  });
  // getschedule com parametro
  const getSchedules = async (): Promise<any> => {
    const baseUrl = process.env.REACT_APP_BASE_API;
    setIsLoading(true);
    const scheduleResponse = await axios.post<any>(
      `${baseUrl}/schedule/getAppointments`,
      bodySchedule,
      config
    )
      .then(response => {
        return setScheduleList(response.data.response);
      }).catch(error => {
        console.log(error);
      });

    setIsLoading(false);
    return scheduleResponse;
  };

  useEffect(() => {
    if (!userDataLoaded) {
      return;
    }

    // criar estado - filter recebendo campos do filtro
    getSchedules();
  }, [userDataLoaded, bodySchedule]);

  dispatch({ type: SET_LOAD, load: false });

  const handleFilter = (): void => {
    //   'X' = 'Cancelado'
    //   'A' = 'Finalizado'
    //   'P' = 'Agendado'
    //   'T' = 'Remarcado'
    //   'H' = 'Pendente'
    const statusList: string[] = [];
    inputFinished && statusList.push('A');
    inputCanceled && statusList.push('X');
    inputRescheduled && statusList.push('T');
    let parsedInitialDate = '';
    let parsedFinalDate = '';
    if (inputInitialDate) {
      const parts = inputInitialDate.split('/');
      parsedInitialDate = format(
        new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0])), 'yyyy-MM-dd'
      );
    } else {
      if (inputFinalDate) parsedInitialDate = '1900-01-01';
    }
    if (inputFinalDate) {
      const parts = inputFinalDate.split('/');
      parsedFinalDate = format(
        new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0])), 'yyyy-MM-dd'
      );
    } else {
      if (inputInitialDate) parsedFinalDate = '9999-12-31';
    }
    setBodySchedule(
      {
        schedulingId: '',
        unityId: '',
        locationId: '',
        resourceId: '',
        serviceId: '',
        medicalRecord: user.prontuario,
        initialDate: parsedInitialDate,
        finalDate: parsedFinalDate,
        registerQuantity: null,
        lastRegister: null,
        schedulingType: null,
        futureScheduling: false,
        statusList: statusList
      }
    );
    setFilterOpen(false);
  };
  const cleanFilter = (): void => {
    setInputInitialDate('');
    setInputCanceled(false);
    setInputFinalDate('');
    setInputFinished(false);
    setInputRescheduled(false);
    setBodySchedule({
      schedulingId: '',
      unityId: '',
      locationId: '',
      resourceId: '',
      serviceId: '',
      medicalRecord: user.prontuario,
      initialDate: '',
      finalDate: '',
      registerQuantity: null,
      lastRegister: null,
      schedulingType: null,
      futureScheduling: false,
      statusList: []
    });
    setFilterOpen(false);
  };
  // console.log(inputCanceled);
  return (
    <>
      <ScheduleContent>
        <Title>
          <h2>Histórico</h2>
        </Title>
        <ButtonsWrapper>
          {/* <div onClick={() => setButtonSlide(!buttonSlide)} className="buttonSlide--path">
            <span className="buttonSlide--label">Todos</span>
            <span className="buttonSlide--label">Agendados</span>
            <button className={`${buttonSlide ? 'left' : 'right'} buttonSlide--toggle`}>{buttonSlide ? 'Todos' : 'Agendados'}</button>
          </div> */}
        </ButtonsWrapper>
        <ScheduleContentMain>
          <Button
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <IconFilter />
            Filtrar
          </Button>
          <FilterWrapper open={filterOpen}>
            <FormWrapper>
              <div className="form-control">
                <Label>Período ou data</Label>
                <br />
                <Input value={inputInitialDate} onChange={(e) => setInputInitialDate(DateM(e.target.value))} width={100} type="text" placeholder="Data Inicial" /> - <Input value={inputFinalDate} onChange={(e) => setInputFinalDate(DateM(e.target.value))} width={100} type="text" placeholder="Data Final" />
              </div>
              <div className="form-control">
                <Label>Status</Label>
                <CheckList>
                  <label className="container">
                    Realizada
                    <input checked={inputFinished} onChange={(e) => setInputFinished(e.target.checked)} type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                  <label className="container">
                    Reagendada
                    <input checked={inputRescheduled} onChange={(e) => setInputRescheduled(e.target.checked)} type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                  <label className="container">
                    Cancelada
                    <input checked={inputCanceled} onChange={(e) => setInputCanceled(e.target.checked)} type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </CheckList>
              </div>
            </FormWrapper>
            <ButtonsWrapperModal>
              <ButtonModal onClick={() => cleanFilter()} className="link">
                Limpar Filtros
              </ButtonModal>
              <ButtonModal
                onClick={() => setFilterOpen(false)}
                className="outline"
              >Cancelar
              </ButtonModal>
              <ButtonModal
                className="primary"
                onClick={() => handleFilter()}
              >
                Aplicar</ButtonModal>
            </ButtonsWrapperModal>
          </FilterWrapper>
          {/* {
            buttonSlide
              ? ( */}
          <ScheduleWrapper>
            {!isLoading
              ? (
                scheduleList?.length === 0 || scheduleList === undefined || scheduleList === 'undefined'
                  ? (
                    <NoSchedule text="Não foram encontrados resultados" noButton />
                  )
                  : (
                    scheduleList?.map((schedule: any, index: number) => {
                      return (
                        <ScheduleCard key={index}>
                          <div className="userInfo">
                            <UserIcon src={
                              // user.imgThumb ??
                              userImage} size='45' margin={'0 1rem 0 0'} />
                            <div>
                              <h2>{`${user.firstName} ${user.lastName}`}</h2>
                              <h3>Titular</h3>
                            </div>
                          </div>
                          <div className="product">
                            <h2>
                              {user.products}
                            </h2>
                            <h3>Predicta</h3>
                          </div>
                          <div className="doctor">
                            <h2>{schedule.doctor}</h2>
                            <h3>{schedule.serviceDescription.split(' ').slice(0, 4).join(' ')}</h3>
                          </div>
                          <div className="date">
                            <h1>{format(parseISO(schedule.appointmentDate), 'dd/MM/yyyy', { locale: ptBR })} | {schedule.appointmentHour}</h1>

                          </div>
                          <div className="files">
                            <a
                              href={schedule.urlExam}
                              className={schedule.urlExam ? '' : 'disabled'}
                              onClick={() => setFileOpen(schedule.appointmentStatus !== 'Cancelada' ? !fileOpen : false)}
                            >
                              <IconFile />
                              <h4>Arquivos</h4>
                            </a>
                          </div>
                          <div className={schedule.appointmentStatus === 'Cancelada' ? 'status canceled' : 'status green'}>
                            {schedule.appointmentStatus}
                          </div>
                        </ScheduleCard>
                      );
                    })
                  )
              )
              : (
                <Load />
              )}

          </ScheduleWrapper>
          {/* ) : (
                <HistoricWrapper>
                  <HistoricCard page="historic" buttonSchedule setResultsOpen={setFileOpen} />
                </HistoricWrapper>
                )
          } */}

        </ScheduleContentMain>

      </ScheduleContent>
    </>
  );
}
